
import {computed, defineComponent, readonly, ref, Ref, watch} from "vue";

export default defineComponent({
  name: "DwRate",
  data() {
    return {}
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    size: {
      type: [Number, String],
      required: false,
      default: 30,
    },
    count: {
      type: Number,
      required: false,
      default: 5,
    }
  },
  setup(props, context) {
    let star: Ref<number> = ref(props.value);

    let isSmile = computed(() => {
      return star.value > 3
    });

    const onRate = (v: number) => {
      star.value = v;
    }

    watch(() => star.value, value => {
      context.emit('update:value', value);
    })

    watch(() => props.value, value => {
      star.value = value;
    })

    return {
      star,
      isSmile,
      onRate,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
